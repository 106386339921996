
.landing-page{
    height: 100vh;
    background-size: cover;
    background-position: center;
    color: #ffffff;
    border-radius: 0px !important;
    margin-bottom: 0 !important;
}
.intro img{
    width: 250px;
}

.intro .btn{
    border: 1px solid;
    margin-top: 30px;
    padding: 6px 26px;
    font-size: 16px;
}

.button-contact, .btn-warning {
	color: white !important;
}

@media (min-width: 220px) {
    .landing-page{
        background-image: url('/images/carousel4.jpg');
    }
}

@media (min-width: 576px) {
    .landing-page{
        background-image: url('/images/carousel1.jpg');
    }
}

@media (min-width: 768px) {
    .landing-page{
        background-image: url('/images/carousel1.jpg');
    }
}
@media (min-width: 992px) {
    .landing-page{
        background-image: url('/images/carousel1.jpg');
    }
}
@media (min-width: 1200px) {
    .landing-page{
        background-image: url('/images/carousel1.jpg');
    }
}

  
  