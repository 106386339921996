.services-title{
    padding-bottom: 10px;
    border-bottom: 3px solid #a6a6a4;
    width: 10%;
}

div{
    display: block;
}

#ServiceTitle{
    color: #132743;
}