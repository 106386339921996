body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Services{
  background: #e8e8e8;
}

.offer-card{
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}

.offer-card:hover{
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.offer-card{
  border-radius: 4px;
  background: transparent !important;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}

@media (min-width: 576px) {
  .offer-card{
    width: 25rem;
  }
}

@media (min-width: 768px) {
  .offer-card{
    width: 20rem;
  }
}

@media (min-width: 992px) {
  .offer-card{
    width: 27rem;
  }
}

@media (min-width: 1200px) {
  .offer-card{
    width: 35rem;
  }
}

html {
  scroll-behavior: smooth;
}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}

a{
  color: black !important;
}

a:hover{
  color: #91bd3a !important;
}