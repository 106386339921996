
.navbar-nav a{
    color: #ffffff !important;
    margin: 0px 20px !important;
    margin-top: 0px;
}

.navbar-toggler {
    color: #ffffff !important;
    border: none !important;
    outline: none !important;
}

.navbar-toggler .fa{
    color: #ffffff;
    font-size: 26px;
}

.nav-link:hover{
    font-weight: 600;
    border-bottom: 1px solid #ffffff;
    margin-bottom: -1px;
}




@media only screen and (max-width: 1000px){
    .navbar {
        background-color: rgba(0, 0, 0, 0.8);
    }
    .navbar-nav a{
        margin: 0 5px 0 17px;
        margin-top: 30px;
    }
    .nav-link:hover{
        border-bottom: none;
    }
}

@media(min-width:768px) {
    .navbar {
    border: 0;
    /*  background-color: transparent; */
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: padding .3s;
    -moz-transition: padding .3s;
    transition: padding .3s;
    }
}