.why-section{
    background-image: url('/images/offer.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #ffffff;
    border-radius: 0px !important;
    margin-bottom: 0 !important;
}

.title{
    font-size: 80px;
    font-family: 'Source Code Pro', monospace;
}

.body-text{
    font-size: 25px;
    margin-top: -25px !important;
    font-family: 'Noto Serif', serif;
}

@media (min-width: 220px) {
    .title{
        font-size: 40px;
    }
}

@media (min-width: 576px) {
    .title{
        font-size: 40px;
    }
}

@media (min-width: 768px) {
    .title{
        font-size: 60px;
    }
}
@media (min-width: 992px) {
    .title{
        font-size: 60px;
    }
}
@media (min-width: 1200px) {
    .title{
        font-size: 80px;
    }
}
