#ServiceIcon{
    font-size: 48px;
    color: #003f5c;
}
.services-bar{
    padding-bottom: 10px;
    border-bottom: 3px solid #a6a6a4;
    width: 25%;
}

.tagTitle{
    color: #132743;
}