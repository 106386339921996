.image-card{
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
}

.card_img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
  
  .image-card:hover{
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  }
  
  .image-card{
    border-radius: 4px;
    background: transparent !important;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    cursor: pointer;
  }

  @media (min-width: 576px) {
    .image-card{
      width: 15rem;
    }
  }
  
  @media (min-width: 768px) {
    .image-card{
      width: 15rem;
    }
  }
  
  @media (min-width: 992px) {
    .image-card{
      width: 20rem;
    }
  }
  
  @media (min-width: 1200px) {
    .image-card{
      width: 25rem;
    }
  }